import { Icons } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

const { SVGLoading } = Icons

export default defineComponent({
	name: 'LoadingIcon',

	props: {
		additionalClasses: { default: '', type: String },
		size: { default: 'small', type: String as PropType<LoadingSize> },
	},

	computed: {
		svgClassSize(): string {
			switch (this.size) {
				case 'xlarge':
					return 'w-40 h-40'
				case 'large':
					return 'w-20 h-20'
				case 'medium':
					return 'w-10 h-10'
				default:
					return 'w-6 h-6'
			}
		},
	},

	render() {
		return (
			<div class={`loading-spinner ${this.additionalClasses}`} data-test="loading-spinner">
				<SVGLoading class="text-brand-blue" svg-class={`antialiased ${this.svgClassSize}`} />
			</div>
		)
	},
})

export type LoadingSize
	= 'large'
	| 'medium'
	| 'small'
	| 'xlarge'
